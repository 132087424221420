import '@/styles/tailwind.css'

const scrollTo = (id) => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo(0, el.offsetTop - 20)
  return false
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  const { pathname } = location

  if (prevRouterProps && prevRouterProps.location) {
    const prevLocation = prevRouterProps.location
    const prevPathname = prevLocation.pathname

    if (prevPathname && prevPathname === pathname) {
      // query param change, do not scroll
      return false
    }
    if (pathname === '/careers/' && prevPathname && prevPathname.match(/\/careers\/\d+\d\//g)) {
      // coming from a job posting, to the carreers page. scroll to careers list.
      window.setTimeout(scrollTo('#careers'), 10)
    }
  }

  return true // use default behavior
}
